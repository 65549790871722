<template>
  <div class="contentD">
    <div class="trapezoid"></div>
    <span class="text-title">设备检索</span>
    <div class="contentD-main">
      <div class="select-tree">
        <div class="d-flex">
          <el-input v-model="searchInfo" placeholder="请输入内容"></el-input>&nbsp;
          <el-button @click="searchequipment" icon="el-icon-search"
            >查找设备</el-button
          >
        </div>
        <div class="d-flex">
          <span class="fen-title">请选择筛选条件</span>
        </div>
        <hr />
        <div class="d-flex">
          <span class="fen-title">所在地区</span>
        </div>
        <hr />
        <div class="d-flex">
          <div
            v-for="(item, index) in regions"
            :key="index"
            @click="regionsClick(item)"
            :class="['item', PlatformVar == item.city_level ? 'actvCss' : '']"
          >
            {{ item.city_level }}
          </div>
        </div>
        <div class="d-flex">
          <span class="fen-title">所在站点</span>
        </div>
        <hr />
        <div class="d-flex">
          <div
            v-for="(item, index) in stationList"
            :key="index"
            @click="stationClick(item)"
            :class="[
              'item',
              zhandian.site_name == item.site_name ? 'actvCss' : '',
            ]"
          >
            {{ item.site_name }}
          </div>
        </div>
        <div class="d-flex">
          <span class="fen-title">设备类型</span>
        </div>
        <hr />
        <div class="d-flex">
          <div
            v-for="(item, index) in types"
            :key="index"
            @click="typeClick(item)"
            :class="['item', type == item.name ? 'actvCss' : '']"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="d-flex">
          <span class="fen-title">电压等级</span>
        </div>
        <hr />
        <div class="d-flex">
          <div
            v-for="(item, index) in dianyaLevels"
            :key="index"
            @click="levelClick(item)"
            :class="['item', dianya == item ? 'actvCss' : '']"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="device-list">
        <div class="contentD-right">
          <div
            v-for="(item, index) in deviceList"
            :key="index"
            class="device-item"
          >
            <div class="img">
              <el-image style="width: 80%; height: auto" :src="equipmentUrl" />
            </div>
            <div class="right">
              <div class="d-flex">名称：{{ item.equipment_name }}</div>
              <div class="d-flex text">投运日期：{{ item.establish_time }}</div>
              <div class="d-flex text">
                <el-button @click="clickEvent(item)">查看详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex position">总共{{ deviceList.length }}条</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cityLevelView,
  cityLevelSiteView,
  queryEquipmentlist,
} from "@/api/service";
import { searchequipmentInfo } from "@/api/index";
export default {
  data() {
    return {
      searchInfo: "",
      regions: [],
      dianyaLevels: ["10kV", "33kV", "220kV"],
      stationList: [],
      types: [
        { name: "变压器", imageUrl: require("../image/trans.png") },
        { name: "开关柜", imageUrl: require("../image/kgg.png") },
        { name: "电缆", imageUrl: require("../image/trans-dl.png") },
      ],
      PlatformVar: "",
      dianya: "10kV",
      zhandian: {},
      type: "变压器",
      equipmentUrl: require("../image/trans.png"),
      deviceList: [],
    };
  },
  methods: {
    searchequipment() {
      searchequipmentInfo(this.searchInfo).then((res) => {
        if (res.code == 200) {
          this.deviceList = res.data;
          console.log(this.deviceList, "设备列表");  
          
          // this.yList = res.data;
          // this.currentPage1 = 1;
        } else {
          this.$message.error(res.error);
        }
      });
    },
    clickEvent(item) {
      console.log(item, "点击的设备");
      let equipmentType;
      if (this.type == "变压器") {
        equipmentType = "byq";
      }
      if (this.type == "开关柜") {
        equipmentType = "kgg";
      }
      if (this.type == "电缆") {
        equipmentType = "dl";
      }
      this.$router.push({
        path: "/statusMng/statusMng",
        query: { equipmentId: item.test_equipment_id, equipmentType },
      });
      // eventBus.$emit('message',{id: this.findIndexOfMenuNamed(JSON.parse(sessionStorage.getItem("permissions")), "/statusMng"), path: "/statusMng/statusMng"})
    },
    // 查找station在数组中的下标
    findIndexOfMenuNamed(array, attributeName) {
      const filteredMenuArray = array.filter(
        (item) => item.attribute === "menu"
      );
      for (let i = 0; i < filteredMenuArray.length; i++) {
        console.log(filteredMenuArray[i].remarks, attributeName);
        if (filteredMenuArray[i].remarks === attributeName) {
          return i; // 返回在过滤后的子集中的索引
        }
      }
      return -1; // 如果没有找到，返回-1
    },
    // 点击区域
    regionsClick(item) {
      this.PlatformVar = item.city_level;
      this.getCitySite(item.city_level);
    },
    // 点击站点
    stationClick(item) {
      this.zhandian = item;
      // let equParam = {
      //       site_transformer_id:item.site_transformer_id,
      //       voltage_level:this.dianya,
      //       equipment_type:this.type
      //     }
      this.getEquipmentList();
    },
    // 点击设备类型
    typeClick(item) {
      this.type = item.name;
      this.equipmentUrl = item.imageUrl;
      // let equParam = {
      //       site_transformer_id:this.zhandian.site_transformer_id,
      //       voltage_level:this.dianya,
      //       equipment_type:this.type
      //     }
      this.getEquipmentList();
    },
    // 点击电压等级
    levelClick(item) {
      this.dianya = item;
      // let equParam = {
      //       site_transformer_id:this.zhandian.site_transformer_id,
      //       voltage_level:this.dianya,
      //       equipment_type:this.type
      //     }
      this.getEquipmentList();
    },
    getCity() {
      cityLevelView().then((res) => {
        if (res.code == 200) {
          console.log(res.data, "城市数据");
          this.regions = res.data;
        }
      });
    },
    getCitySite(param) {
      cityLevelSiteView(param).then((res) => {
        if (res.code == 200) {
          console.log(res.data, "城市站点");
          this.stationList = res.data;
        }
      });
    },
    getEquipmentList(param) {
      let equParam = {
        site_transformer_id: this.zhandian.site_transformer_id,
        voltage_level: this.dianya,
        equipment_type: this.type,
      };
      queryEquipmentlist(equParam).then((res) => {
        console.log(res.data, "设备列表");
        this.deviceList = res.data;
      });
    },
  },
  mounted() {
    this.getCity();
  },
};
</script>

<style scoped lang="scss">
.contentD {
  width: 95%;
  height: calc(100vh - 200px);
  margin: 0px auto;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-contentd: center;
}
.trapezoid {
  width: 400px;
  height: 50px;
  background-color: transparent;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 50px solid #9fce9b75; /* 梯形的高度 */
  /* 旋转梯形 */
  transform: rotate(180deg);
  /* 如果你想让梯形居中显示，可以添加以下样式 */
  display: flex;
  justify-contentd: center;
  align-items: center;
  margin: 0px auto; /* 上下外边距和水平居中 */
}
.text-title {
  font-size: 24px;
  color: #fff;
  font-weight: 800;
  position: absolute;
  top: 10px;
  left: 47.5%;
}
.contentD-main {
  display: flex;
  height: 100%;
}
.select-tree {
  flex: 1;
  margin: 10px;
  .fen-title {
    color: rgb(235, 228, 228);
    font-size: 20px;
    margin-top: 30px;
  }
  hr {
    border: none; /* 移除默认的边框样式 */
    height: 1px; /* 设置高度 */
    background-color: #126297; /* 设置背景颜色即为 <hr> 的颜色 */
    width: 100%; /* 你可以根据需要设置宽度 */
    margin-top: 5px;
  }
  .item {
    padding: 5px 20px;
    color: #666;
    border-radius: 3px;
    border: 1px solid rgb(15, 81, 119);
    margin-right: 1.25%;
    margin-top: 8px;
    font-size: 24px;
  }
  .actvCss {
    background-color: #0463a15e;
    color: #fff;
  }
}
.device-list {
  flex: 1;
  margin: 10px;
  height: calc(100vh - 250px);
  overflow-y: scroll;
  .contentD-right {
    display: flex;
    flex-wrap: wrap;
  }
  .device-item {
    display: flex;
    border-bottom: 1px solid rgb(64, 159, 214);
    color: rgba(248, 248, 248, 0.8);
    margin-top: 8px;
    font-size: 18px;
    .img {
      flex: 1;
      width: 206px;
      height: 194px;
    }
    .right {
      flex: 1;
      margin: auto 0;
      .text {
        margin-top: 10px;
      }

      /deep/ .el-button {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        color: rgb(72, 164, 250);
        font-size: 18px;
        cursor: pointer;
        border: 2px solid #02a325;
        background: #053a1957;
      }
    }
  }
  .position {
    position: absolute;
    bottom: 1%;
    right: 9%;
    font-size: 18px;
    color: #fff;
  }
}
/deep/ .el-input__inner {
  padding: 0 4px; /* 调整内边距 */
  border: 2px solid #03a727;
  background: linear-gradient(to right, #163a7c57, rgba(3, 57, 138, 0.267));
  font-size: 24px;
  color: #fff;
  height: 53px;
}
/deep/ .el-input__inner:hover {
  box-shadow: 2px 4px 5px rgba(42, 223, 255, 0.1),
    0 0 10px rgba(9, 160, 248, 0.2) inset; /* Improved box-shadow on hover */
  background: linear-gradient(to right, #163a7c57, rgba(29, 165, 255, 0.267));
  border-color: rgb(35, 211, 255);
}
/deep/ .el-input {
  width: 300px; /* 设置固定宽度 */
  height: 53px;
}
/deep/ .el-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  color: rgb(72, 164, 250);
  font-size: 24px;
  cursor: pointer;
  border: 2px solid #02a325;
  background: #053a1957;
}
/deep/ .el-button:hover {
  box-shadow: 2px 4px 5px rgba(42, 223, 255, 0.1),
    0 0 10px rgba(9, 160, 248, 0.2) inset; /* Improved box-shadow on hover */
  background: linear-gradient(to right, #163a7c57, rgba(30, 240, 247, 0.267));
  border-color: rgb(35, 211, 255);
}
</style>
